<template>
  <div>
    <div class="innerPage A4Container flexCloumnCenter" v-for="(item, index) in enterArr" :key="index">
      <div class="trans">
        <div class="title flexCenter" style="width: 100%;height: 40px;">
          {{ year }} 年{{ info.school_name }}学生顶岗实习审批表
        </div>
        <div class="title flexBetween" style="width: 100%;height: 40px;">
          <span style="min-width: 100px;">学校：{{info.school_name||'-'}}</span>
          <span style="min-width: 100px;">填报时间：{{info.report_date||'-'}}</span>
        </div>
        <div class="form" style="height: calc( 100% - 40px );">
          <el-row class="borderR" v-if="index === 0">
            <el-col :span="3" class="flexCenter cell cell50">班级</el-col>
            <el-col :span="3" class="flexCenter cell cell50">{{info.class_name||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell50">人数</el-col>
            <el-col :span="3" class="flexCenter cell cell50">{{info.total||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell50">班主任</el-col>
            <el-col :span="3" class="flexCenter cell cell50">{{info.teacher_name||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell50">指导老师</el-col>
            <el-col :span="3" class="flexCenter cell cell50">{{info.mentor_name||'-'}}</el-col>
          </el-row>
          <el-row class="borderR" v-if="index === 0">
            <el-col :span="3" class="flexCenter cell cell80">专业</el-col>
            <el-col :span="3" class="flexCenter cell cell80">{{info.major_name||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell80">实习起止时间</el-col>
            <el-col :span="3" class="flexCenter cell cell80" style="text-align: center">
              {{info.start_time}} <br> 至 <br> {{info.end_time}}
            </el-col>
            <el-col :span="3" class="flexCenter cell cell80">联系电话</el-col>
            <el-col :span="3" class="flexCenter cell cell80">{{info.phone||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell80">联系电话</el-col>
            <el-col :span="3" class="flexCenter cell cell80">{{info.mentor_tel||'-'}}</el-col>
          </el-row>
          <el-row class="borderR" v-if="index === 0">
            <el-col :span="3" class="flexCenter cell80">
              <el-row class="">
                <el-col :span="12" class="flexCenter cell cell80">实习渠道</el-col>
                <el-col :span="12" class="flexCenter cell cell80">学校推荐人数</el-col>
              </el-row>
            </el-col>
            <el-col :span="3" class="flexCenter cell cell80">{{info.xxtj || '-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell80">学生自联人数</el-col>
            <el-col :span="3" class="flexCenter cell cell80">{{info.xszl || '-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell80">中介介绍人数</el-col>
            <el-col :span="3" class="flexCenter cell cell80">-</el-col>
            <el-col :span="3" class="flexCenter cell cell80">其他渠道实习人数</el-col>
            <el-col :span="3" class="flexCenter cell cell80">{{info.qtqd || '-'}}</el-col>
          </el-row>
          <el-row class="borderR">
            <el-col :span="3" class="flexCenter cell cell50">实习单位名称</el-col>
            <el-col :span="3" class="flexCenter cell cell50">性质</el-col>
            <el-col :span="3" class="flexCenter cell cell50">部门</el-col>
            <el-col :span="3" class="flexCenter cell cell50">岗位</el-col>
            <el-col :span="2" class="flexCenter cell cell50">实习人数</el-col>
            <el-col :span="3" class="flexCenter cell cell50" style="text-align: center;line-height:unset;">实习待遇<br/>(元/月)</el-col>
            <el-col :span="2" class="flexCenter cell cell50">单位联系人</el-col>
            <el-col :span="3" class="flexCenter cell cell50">联系电话</el-col>
            <el-col :span="2" class="flexCenter cell cell50">备注</el-col>
          </el-row>
          <el-row class="borderR" v-for="(enter,key) in item" :key="key">
            <el-col :span="3" class="flexCenter cell cell80">{{enter.firm_name||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell80">{{enter.enter_info||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell80">{{enter.department||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell80">{{enter.job||'-'}}</el-col>
            <el-col :span="2" class="flexCenter cell cell80">{{enter.total||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell80">{{enter.salary||'-'}}</el-col>
            <el-col :span="2" class="flexCenter cell cell80">{{enter.firm_content_name||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell80">{{enter.firm_content_tel||'-'}}</el-col>
            <el-col :span="2" class="flexCenter cell cell80">{{enter.remarks||'-'}}</el-col>
          </el-row>
          <template v-if="index === enterArr.length - 1">
            <el-row class="borderR borderB flexStretch" style="height: calc( 100% - 480px );">

              <el-col :span="3" class="flexCenter cell cell50" style="text-align: center">学 校<br/>意 见</el-col>
              <el-col :span="9" class="flexCenter cell" style="padding: 10px;">
                <div :id="'divCntnrA_'+idx"
                     style="width:100%;height: 100%;overflow: hidden;box-sizing: border-box;position: relative;">
                  <div :id="'resizeDivA_'+idx" style="width: 100%;word-break: break-word">
                    {{ info.visit_summary || '-' }}
                  </div>

                  <div class="flexBetween" style="position: absolute;bottom: 0;width: calc( 100% - 20px );line-height: 50px;">
                    <div>校长（签名）: </div>
                    <div> &nbsp;&nbsp;&nbsp;&nbsp;年 &nbsp;&nbsp;&nbsp;&nbsp; 月 &nbsp;&nbsp;&nbsp;&nbsp; 日（盖章）</div>
                  </div>
                </div>
              </el-col>
              <el-col :span="3" class="flexCenter cell cell50" style="text-align: center">审 批<br/>意 见</el-col>
              <el-col :span="9" class="cell" style="padding: 10px;">


                <div :id="'divCntnrB_'+idx"
                     style="width:100%;height: 100%;overflow: hidden;box-sizing: border-box;position: relative;">
                  <div :id="'resizeDivB_'+idx" style="width: 100%;word-break: break-word">
                    {{ info.visit_summary || '-' }}
                  </div>

                  <div class="flexBetween" style="position: absolute;bottom: 0;width: calc( 100% - 20px );line-height: 50px;">
                    <div>审批人（签名）: </div>
                    <div> &nbsp;&nbsp;&nbsp;&nbsp;年 &nbsp;&nbsp;&nbsp;&nbsp; 月 &nbsp;&nbsp;&nbsp;&nbsp; 日（盖章）</div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <p style="font-size: 14px;line-height: 24px;margin-top: 5px;">注：本表按班级填报，一式二份，经审批后学校、市职教教研室各存一份。
              单位性质：国有企业、外资企业、合资企业、私营企业、事业单位、国家机关、个人创业、其他（详细说明）</p>
          </template>
          <el-row class="borderR" v-else>
            <el-col :span="24" style="border-top: 1px solid #333;"></el-col>
          </el-row>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'roll',
  data() {
    return {
      dataover: false,
      userid: '',
      year:'',
      enterArr: [],
    }
  },
  props: {
    info: {
      type: Object,
      default: {}
    },
    idx: {
      type: Number,
      default: 1
    },
  },
  mounted() {
    let _this = this
    this.year = new Date().getFullYear()
    if (_this.info.username) {
      _this.$nextTick(() => {
        _this.resizeA()
      })
    }
    this.pagination()
  },
  methods: {
    resizeA() {
      let divHeight = document.getElementById(`divCntnrA_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
      console.log('a', divHeight, textHeight)
      let i = 1
      while (textHeight > divHeight && i < 5) {
        document.getElementById(`resizeDivA_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
        i++
      }
    },
    pagination() {
      let enters = this.info.enters
      let limit = 6
      let total = enters.length
      if (total >= limit) {
        total += 2
        let totalPage = Math.ceil(total/limit)
        for (let i = 0; i < totalPage; i++) {
          if (i === 0) {
            this.enterArr.push(enters.splice(0, limit-2))
          } else {
            if (i === totalPage - 1 && enters.length > 6) {
              this.enterArr.push(enters.splice(0,6))
              this.enterArr.push(enters.splice(0,enters.length))
            } else {
              this.enterArr.push(enters.splice(0, limit))
            }
          }
        }
      } else {
        this.enterArr.push(enters.splice(0, total))
      }
    }
  }
}
</script>

<style lang="less" type="text/less">
.stuRolls .ant-col {
  display: flex;
}
</style>
<style lang="less" type="text/less" scoped>
.innerPage {
  font-size: 20px;
  box-sizing: border-box;
  margin: 0 auto;

  .borderR {
    border-right: 1px solid #333;
  }

  .borderB {
    border-bottom: 1px solid #333;
  }

  .cell {
    border-top: 1px solid #333;
    border-left: 1px solid #333;
    word-break: break-all;

    min-height: 30px;
    font-size: 14px;
    white-space: normal;
  }

  .cell50 {
    min-height: 50px;
    line-height: 50px;
    font-size: 14px;
  }

  .cell80 {
    min-height: 80px;
    font-size: 14px;
    text-align: center;
  }

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .form {
    width: 100%;
    height: calc(100% - 50px);
    padding: 0;
    border: 0;

    div {
      font-size: 14px;
      color: #323232;
      box-sizing: border-box;
    }

  }
}

.trans {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 40px;
  transform: rotate(-90deg);

}
</style>
